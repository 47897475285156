import { createAction, props } from '@ngrx/store';
import {
  CheckExistenceRequest,
  PairWithUser,
  CreateOrUpdateUserRequest,
} from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { RemovePairPayload } from '../../models';

export namespace AdminActions {
  export const LoadUsers = createAction('[TELEMATIC-LAB] LOAD_USERS');
  export const LoadUsersSuccess = createAction(
    '[TELEMATIC-LAB] LOAD_USERS_SUCCESS',
    props<{ payload: PairWithUser[] }>(),
  );
  export const LoadUsersError = createAction(
    '[TELEMATIC-LAB] LOAD_USERS_ERROR',
  );

  export const PostCheckExistUser = createAction(
    '[TELEMATIC-LAB] POST_CHECK_EXIST_USER',
    props<{ payload: CheckExistenceRequest }>(),
  );
  export const PostCheckExistUserSuccess = createAction(
    '[TELEMATIC-LAB] POST_CHECK_EXIST_USER_SUCCESS',
    props<{ payload: number }>(),
  );
  export const PostCheckExistUserError = createAction(
    '[TELEMATIC-LAB] POST_CHECK_EXIST_USER_ERROR',
  );

  export const PostCheckExistEquipment = createAction(
    '[TELEMATIC-LAB] POST_CHECK_EXIST_EQUIPMENT',
    props<{ payload: CheckExistenceRequest }>(),
  );
  export const PostCheckExistEquipmentSuccess = createAction(
    '[TELEMATIC-LAB] POST_CHECK_EXIST_EQUIPMENT_SUCCESS',
    props<{ payload: number }>(),
  );
  export const PostCheckExistEquipmentError = createAction(
    '[TELEMATIC-LAB] POST_CHECK_EXIST_EQUIPMENT_ERROR',
  );

  export const UpdateUser = createAction(
    '[TELEMATIC-LAB] UPDATE_USER',
    props<{ payload: CreateOrUpdateUserRequest }>(),
  );
  export const UpdateUserSuccess = createAction(
    '[TELEMATIC-LAB] UPDATE_USER_SUCCESS',
  );
  export const UpdateUserError = createAction(
    '[TELEMATIC-LAB] UPDATE_USER_ERROR',
  );

  export const RemovePair = createAction(
    '[TELEMATIC-LAB] REMOVE_PAIR',
    props<{ payload: RemovePairPayload }>(),
  );
  export const RemovePairSuccess = createAction(
    '[TELEMATIC-LAB] REMOVE_PAIR_SUCCESS',
  );
  export const RemovePairError = createAction(
    '[TELEMATIC-LAB] REMOVE_PAIR_ERROR',
  );
}
