import { Injectable, inject } from '@angular/core';
import { DsSnackbarType } from '@design-system/feature/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LabService } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootActions } from '../root/root.actions';
import { AdminActions } from './admin.actions';

@Injectable()
export class AdminEffects {
  private actions$: Actions = inject(Actions);

  LoadAssignedEquipments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.LoadUsers),
      switchMap(() =>
        this.adminService.getAllPairsWithUserData().pipe(
          map((data) =>
            AdminActions.LoadUsersSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            AdminActions.LoadUsersError(),
            RootActions.ShowErrorSnackbar(),
          ]),
        ),
      ),
    ),
  );

  PostCheckUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.PostCheckExistUser),
      switchMap((payload) =>
        this.adminService.checkExistence(payload.payload, 'response').pipe(
          map((data) =>
            AdminActions.PostCheckExistUserSuccess({
              payload: data.status,
            }),
          ),
          catchError(() => [
            AdminActions.PostCheckExistUserError(),
            RootActions.ShowErrorSnackbar(),
          ]),
        ),
      ),
    ),
  );

  PostCheckEquipment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.PostCheckExistEquipment),
      switchMap((payload) =>
        this.adminService.checkExistence(payload.payload, 'response').pipe(
          map((data) =>
            AdminActions.PostCheckExistEquipmentSuccess({
              payload: data.status,
            }),
          ),
          catchError(() => [
            AdminActions.PostCheckExistEquipmentError(),
            RootActions.ShowErrorSnackbar(),
          ]),
        ),
      ),
    ),
  );

  UpdateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.UpdateUser),
      switchMap((payload) =>
        this.adminService.createOrUpdateUser(payload.payload, 'response').pipe(
          map(() => AdminActions.UpdateUserSuccess(), AdminActions.LoadUsers()),
          catchError(() => [
            AdminActions.UpdateUserError(),
            RootActions.ShowErrorSnackbar(),
          ]),
        ),
      ),
    ),
  );

  RemovePair$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminActions.RemovePair),
      switchMap((payload) =>
        this.adminService
          .removePairById(payload.payload.email, payload.payload.id, 'response')
          .pipe(
            map(
              () => AdminActions.RemovePairSuccess(),
              RootActions.ShowSnackbar({
                message: this.translatePipe.instant(
                  'telematic_lab.message.request_succeeded',
                ),
                snackbar_type: DsSnackbarType.Success,
              }),
            ),
            catchError(() => [
              AdminActions.RemovePairError(),
              RootActions.ShowErrorSnackbar(),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private translatePipe: TranslateService,
    private adminService: LabService,
  ) {}
}
