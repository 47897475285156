import { createSelector } from '@ngrx/store';
import { TelematicLabState } from '..';

export namespace AdminSelectors {
  const getAdminState = (state: TelematicLabState) => state.admin;

  export const getUsers = createSelector(getAdminState, (state) => state.users);

  export const getIsUsersLoading = createSelector(
    getAdminState,
    (state) => state.isUsersLoading,
  );

  export const getUserExisting = createSelector(
    getAdminState,
    (state) => state.isUserExisting,
  );

  export const getUserExistingLoading = createSelector(
    getAdminState,
    (state) => state.isUserExistingLoading,
  );

  export const getEquipmentExisting = createSelector(
    getAdminState,
    (state) => state.isEquipmentExisting,
  );

  export const getEquipmentExistingLoading = createSelector(
    getAdminState,
    (state) => state.isEquipmentExistingLoading,
  );

  export const getIsEquipmentRemoved = createSelector(
    getAdminState,
    (state) => state.isEquipmentRemoved,
  );

  export const getIsUserUpdatePending = createSelector(
    getAdminState,
    (state) => state.isUserUpdatePending,
  );
}
