import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { PairWithUser } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { AdminActions } from './admin.actions';

export interface AdminState extends EntityState<any> {
  users: PairWithUser[];
  isUsersLoading: boolean;
  isUserExisting: boolean;
  isUserExistingLoading: boolean;
  isEquipmentExisting: boolean;
  isEquipmentExistingLoading: boolean;
  isEquipmentRemoved: boolean;
  isUserUpdatePending: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: AdminState = adapter.getInitialState({
  users: [] as PairWithUser[],
  isUsersLoading: true,
  isUserExisting: false,
  isUserExistingLoading: false,
  isEquipmentExisting: false,
  isEquipmentExistingLoading: false,
  isEquipmentRemoved: false,
  isUserUpdatePending: false,
});

const _adminReducer = createReducer(
  initialState,
  on(AdminActions.LoadUsers, (state) => ({
    ...state,
    isUsersLoading: true,
  })),
  on(AdminActions.LoadUsersSuccess, (state, { payload }) => ({
    ...state,
    users: payload,
    isUsersLoading: false,
  })),
  on(AdminActions.LoadUsersError, (state) => ({
    ...state,
    isUsersLoading: false,
  })),

  on(AdminActions.PostCheckExistUser, (state) => ({
    ...state,
    isUserExisting: false,
    isUserExistingLoading: true,
  })),
  on(AdminActions.PostCheckExistUserSuccess, (state, { payload }) => ({
    ...state,
    isUserExisting: payload === 200,
    isUserExistingLoading: false,
  })),
  on(AdminActions.PostCheckExistUserError, (state) => ({
    ...state,
    isUserExistingLoading: false,
  })),

  on(AdminActions.PostCheckExistEquipment, (state) => ({
    ...state,
    isEquipmentExisting: false,
    isEquipmentExistingLoading: true,
  })),
  on(AdminActions.PostCheckExistEquipmentSuccess, (state, { payload }) => ({
    ...state,
    isEquipmentExisting: payload === 200,
    isEquipmentExistingLoading: false,
    isUserUpdatePending: false,
  })),
  on(AdminActions.PostCheckExistEquipmentError, (state) => ({
    ...state,
    isEquipmentExistingLoading: false,
  })),
  on(AdminActions.RemovePair, (state) => ({
    ...state,
    isEquipmentRemoved: false,
  })),
  on(AdminActions.RemovePairSuccess, (state) => ({
    ...state,
    isEquipmentRemoved: true,
  })),
  on(AdminActions.UpdateUser, (state) => ({
    ...state,
    isUserUpdatePending: false,
  })),
  on(AdminActions.UpdateUserSuccess, (state) => ({
    ...state,
    isUserUpdatePending: true,
  })),
);
export function adminReducer(state, action: Action): AdminState {
  return _adminReducer(state, action);
}
